<template>
  <div class="ml_privacy">
    <body style="tab-interval: 36pt">
      <div class="Section0" style="layout-grid: 15.6pt">
        <p
          class="MsoTitle"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-autospace: ideograph-numeric;
            mso-line-height-alt: 12pt;
            text-align:center;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size:20px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Regular"
                >车赢云平台用户使用协议</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 22pt;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >欢迎您使用车赢云平台及相关服务</font
              ><font face="PingFang SC Semibold">!</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">为了更好地为您提供服务</font
            ><font face="PingFang SC Regular"
              >,请您仔细阅读《用户使用协议》(以下简称“本协议”)。在您开始使用车赢云平台及相关服务之前,请您务必认真阅读并充分理解本协议,特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、法律适用和争议解决条款等。其中,免除或者限制责任条款等重要内容将以加粗形式提示您注意,您应重点阅读并确定已理解其全部内容（如您无法理解相关条款涵义，可向公司客服等寻求协助后再确定是否同意相关条款）。如您未满18周岁,</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 1pt;
            "
            >&nbsp;<font face="PingFang SC Regular">不得使用</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >除非您完全接受本协议的全部内容</font
            ><font face="PingFang SC Regular"
              >,否则您无权下载、安装、使用(以下统称“使用”)车赢云平台,或者通过任何方式使用车赢云平台服务,或者获得车赢云平台提供的任何服务。若您下载、安装、登录使用车赢云平台及相关服务,则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">1、适用范围</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议是您与上海车赢信息技术有限公司</font
            ><font face="PingFang SC Regular"
              >(包括上海车赢信息技术有限公司的关联公司、控制公司,以下合称“公司”)之间就您下载、安装、使用车赢云平台的客户端应用程序、公司经所属企业(定义见下文第1.4条)授权为其开发和维护的微信小程序(“所属企业微信小程序”),并获得车赢云平台提供的用户服务所订立的协议。就本协议项下涉及的某些服务,可能会由上海车赢信息技术有限公司的关联公司、控制公司向您提供,您知晓并同意接受上述服务内容,即视为接受双方之间的相关权利义务关系亦受本协议约束。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >“用户”指所有直接或间接获取和使用车赢云平台及相关服务的使用者,包括自然人、法人和其他组织等；在本协议中称为“用户”或称为“您”。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >“车赢云平台”指由公司合法拥有并运营的、标注名称为车赢云平台的客户端应用程序、所属企业微信小程序、公司向用户提供的与车赢云平台相关的服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >“所属企业”指您受其雇佣或为其服务的公司或其他组织。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议内容同时包括公司已经发布及后续可能不断发布的关于车赢云平台及相关服务的相关协议、规则等内容前述内容一经正式发布</font
            ><font face="PingFang SC Regular"
              >,并以适当的方式送达用户(系统通知等),即为本协议不可分割的组成部分，您应同样遵守。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台的下载、更新和卸载</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.6.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您使用车赢云平台及相关服务</font
            ><font face="PingFang SC Regular"
              >,可以通过预装、从公司或公司已授权的第三方下载等方式获取车赢云平台。若您并非从公司或经公司授权的第三方获取车赢云平台的,公司无法保证非官方版本的车赢云平台能够正常使用,您因此遭受的损失与公司无关。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.6.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司可能为不同的终端设备开发了不同的应用程序软件版本</font
            ><font face="PingFang SC Regular"
              >,您应当根据实际设备状况获取、下载、安装相匹配的版本。由于软件与终端设备型号不匹配所导致的任何问题或损失,均由您自行承担。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.6.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您可根据自行需要使用车赢云平台及相关服务或其更新版本</font
            ><font face="PingFang SC Regular"
              >,如您不再需要使用车赢云平台及相关服务可自行卸载。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.6.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">为更好的提升用户体验及服务</font
            ><font face="PingFang SC Regular"
              >,公司将不定期提供软件更新或改变(包括但不限于软件修改、升级、功能强化、开发新服务和软件替换等),公司无义务就此向您特别通知。为保证车赢云平台及相关服务安全、提升用户服务，车赢云平台及相关服务更新或部分服务内容更新后,在可能的情况下,公司将以包括但不限于系统提示、公告和站内信等方式提示用户,用户有权选择接受更新版本或服务,如用户不接受,部分功能将受到限制或不能继续使用。公司无义务保障旧版本软件持续可用,也无义务提供相应的客户服务,对用户使用过时版本导致的服务中断或受阻,公司不承担责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.7</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台的授权许可范围</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.7.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台及相关服务中的部分功能</font
            ><font face="PingFang SC Regular"
              >,在您或者所属企业满足了公司现有规定的前提下,公司许可您根据本协议在授权范围内合法使用车赢云平台及相关服务的功能,该许可为不可转让及非排他的许可。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.7.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您有权使用的车赢云平台的功能的范围受限于您或者所属企业与公司签署的使用协议和保密条款等约定以及所属企业对您的授权范围。所属企业基于企业管理需求</font
            ><font face="PingFang SC Regular"
              >,对您的企业组织成员身份及基于该身份的相关权益进行管理,包括但不限于加入、删除以及调整权限和限制服务内容等。您保证在遵守本协议的同时,按照所属企业的授权范围使用车赢云平台及相关服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.7.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议未明示授予您的其他一切权利仍由公司保留</font
            ><font face="PingFang SC Regular"
              >,您在相应使用车赢云平台涉及上述权利时须另行获得公司的书面许可,同时公司如未主动行使前述任何权利并不构成对该权利的放弃。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.7.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司可能根据实际需要对加车赢云平台及相关服务的所有标准、范围和方式进行修改和变更。在上述的修改和变更前</font
            ><font face="PingFang SC Regular"
              >,公司会以适当方式进行通知或公告,如您不同意上述修改或变更,您应当停止使用该服务并立即从各终端设备中不可恢复地删除车赢云平台及其数据。如公司降低了标准或扩大了服务的范围,在调整之前符合原标准和原服务范围的原用户,也应按照新的标准和服务范围执行。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台及相关服务的使用</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解</font
            ><font face="PingFang SC Regular"
              >,您使用车赢云平台及相关服务需自行准备与车赢云平台及相关服务有关的终端设备(如手机等),一旦您在终端设备中运行车赢云平台,即视为您使用车赢云平台及相关服务。为充分实现车赢云平台的全部功能,您可能需要将其终端设备联入互联网,您理解您需自行向网络运营商了解相关资费信息,并由您自行承担所需要的费用(如流量费、上网费等)。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您不得以任何形式对车赢云平台及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像、反向工程、反向汇编、数据爬取等未经授权的访问或使用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您有义务和责任妥善保管您的车赢云平台的注册信息、账号和密码的安全</font
            ><font face="PingFang SC Regular"
              >,并对您的注册账号及密码下的行为承担法律责任,包括但不限于您所发表的任何内容、评论、您的行为(如推荐、分享)、您发布内容被他人转发、分享或以其他方式传播、您所指定的账号管理员的行为以及由前述行为产生的任何后果。如您怀疑他人盗用或在使用您的账号时,您应当立即通知公司,且您同意并确认,除法律另有规定外,公司不对上述情形产生的任何直接或间接的损失承担责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >企业组织用户应当自行申请、添加或指定管理员账号并对管理员的行为自行承担责任。公司根据企业用户管理员的行为对其所属企业服务的调整也由管理员所属企业承担责任</font
            ><font face="PingFang SC Regular"
              >,因此给管理员所属企业或其他人造成损失的,公司不承担责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您有义务对车赢云平台及相关服务中您以外的其他用户提供的内容自行进行判断</font
            ><font face="PingFang SC Regular"
              >,并承担因使用上述内容而引起的所有风险,包括因对该等内容的真实性、准确性、完整性或实用性的依赖而产生的风险。</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >公司无法且不会对其他用户提供的内容的真实性、准确性、完整性、实用性和时效性承担任何责任</font
              ><font face="PingFang SC Regular"
                >,也不对您信赖上述内容而造成的损失或损害承担赔偿责任。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台转载作品是为了传递更多信息以更好为用户提供服务</font
            ><font face="PingFang SC Regular"
              >,并不代表公司或车赢云平台认同转载作品的观点和对其内容负责。</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >您应自行判断车赢云平台转载作品的内容的真实性、准确性、完整性及实用性；如您依赖其行事</font
              ><font face="PingFang SC Regular"
                >,由此产生的法律后果由您自行承担。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.7</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">未经适当授权</font
            ><font face="PingFang SC Regular"
              >,您不得在车赢云平台上制作任何所属企业之外的其他企业的名片或宣传所属企业之外的其他企业,如果您违反本条约定制作其他企业的名片或宣传其他企业的,公司有权随时终止您对车赢云平台的使用,并要求您承担法律责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">1.8.8</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 1pt;
            "
            ><font face="PingFang SC Regular"
              >如授权登录操作员工从所属企业离职的，所属企业应及时删除该离职人员在车赢云平台上的信息和授权权限</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">,</font></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >离职人员在离职后继续使用车赢云平台而产生的任何后果均由所属企业承担；给公司造成损失的</font
              ><font face="PingFang SC Regular"
                >,公司有权要求所属企业进行赔偿。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >1.8.9您通过或使用车赢云平台访问第三方网站或获取第三方提供的产品或服务时,因第三方网站、程序(包括小程序)及其功能、内容导致的用户风险和损失,由用户自行承担,由此导致的纠纷由您与该第三方共同解决,公司不承担任何责任。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">公司也不为第三方网站和程序</font
            ><font face="PingFang SC Regular"
              >(包括小程序)提供任何的技术支持或客服支持。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">2、用户行为规范</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >用户行为要求您应当对您使用车用云平台及相关服务的行为负责</font
            ><font face="PingFang SC Regular"
              >,除非法律允许或者经公司事先书面许可,您使用车赢云平台及相关服务不得具有下列行为，否则公司有权停止对您或所属公司的相关服务，给公司造成损失的，公司有权向您及/或所属公司主张赔偿：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >使用未经公司授权或许可的任何插件、外挂、系统或第三方工具登陆、使用或对车赢云平台软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响</font
            ><font face="PingFang SC Regular"
              >,或制作、发布、传播上述工具。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >通过修改或伪造软件运行中的指令、数据等任何方式</font
            ><font face="PingFang SC Regular"
              >,增加、删减、更改车赢云平台的功能或运行效果,或者运营用于上述用途的软件、方法或将其向公众传播,无论这些行为是否为商业目的。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对车赢云平台进行反向工程、反向汇编、编译或者以其他方式尝试发现车赢云平台的源代码。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >删除车赢云平台或其副本上关于著作权的信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >利用或针对车赢云平台及相关服务进行任何危害计算机网络安全的行为</font
            ><font face="PingFang SC Regular">,包括但不限于：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >明知他人从事危害网络安全的活动的</font
            ><font face="PingFang SC Regular"
              >,为其提供技术支持、支付结算等帮助；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >使用未经许可的数据或进入未经许可的服务器</font
            ><font face="PingFang SC Regular">/账号；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">未经许可</font
            ><font face="PingFang SC Regular"
              >,企图探查、扫描、测试车赢云平台或网络的弱点或其它实施破坏网络安全的行为；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >企图干涉、破坏车赢云平台系统或网站的正常运行</font
            ><font face="PingFang SC Regular"
              >,故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">伪造</font
            ><font face="PingFang SC Regular"
              >TCP/IP数据包名称或部分名称。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.1.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">在任何情况下</font
              ><font face="PingFang SC Regular"
                >,如果公司有理由认为您的任何行为违反或可能违反上述约定的,公司可独立进行判断并及时处理,且在任何时候有权在不进行任何事先通知的情况下终止向您及/或所属企业提供服务,并追究相关主体责任。</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >用户使用车赢云平台及相关服务应自觉遵守宪法法律、法规、遵守公共秩序</font
            ><font face="PingFang SC Regular"
              >,尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。用户不得使用车赢云平台及相关服务制作、复制、发布、传送、传播、储存含有下列内容的信息：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >违反宪法确定的基本原则的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">危害国家安全</font
            ><font face="PingFang SC Regular">,泄露国家秘密的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">颠覆国家政权</font
            ><font face="PingFang SC Regular"
              >,推翻社会主义制度煽动分裂国家、破坏国家统一的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >损害国家荣誉和利益的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >宣扬恐怖主义、极端主义的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">宣扬民族仇恨、民族歧视</font
            ><font face="PingFang SC Regular">,破坏民族团结的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >煽动地域歧视、地域仇恨的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">破坏国家宗教政策</font
            ><font face="PingFang SC Regular">,宣扬邪教和迷信的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">编造、散布谣言、虚假信息</font
            ><font face="PingFang SC Regular"
              >,扰乱经济秩序和社会秩序、破坏社会稳定的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >侵害未成年人合法权益或者损害未成年人身心健康的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">未获他人允许</font
            ><font face="PingFang SC Regular"
              >,偷拍、偷录他人,侵害他人合法权利的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">侮辱或者诽谤他人</font
            ><font face="PingFang SC Regular">,侵害他人合法权益的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对他人进行骚扰、暴力恐吓、威胁</font
            ><font face="PingFang SC Regular">,实施人肉搜索的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >涉及他人隐私、个人信息或资料的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">散布污言秽语</font
            ><font face="PingFang SC Regular">,损害社会公序良俗的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >侵犯他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益内容的</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">散布商业广告</font
            ><font face="PingFang SC Regular"
              >,或类似的商业招揽信息、过度营销信息及垃圾信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >使用车赢云平台软件常用语言文字以外的其他语言文字评论的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >不遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等</font
            ><font face="PingFang SC Regular">“七条底线”要求的；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >其他违反法律法规、规范性文件、政策及公序良俗、社会公德、干扰车赢云平台软件及相关服务正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">2.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您使用车赢云平台软件及相关服务过程中不得从事下列行为：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(a)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">提交、发布虚假信息</font
            ><font face="PingFang SC Regular"
              >,或冒充、利用他人名义发布信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(b)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >强制、诱导其他用户关注账号、点击链接或分享信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(c)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >虚构事实、隐瞒真相或者其他误导、欺骗他人行为；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(d)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >侵害他人的名誉权、肖像权、知识产权、商业秘密等合法权利；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(e)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">违法犯罪活动；</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(f)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >导致或可能导致公司与第三方产生纠纷、争议或诉讼的行为；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">(</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">g</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >删除、隐匿、改变车赢云平台软件及相关服务显示或其中包含的任何专利、著作权、商标或其他权利声明；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">（</font
              ><font face="PingFang SC Semibold">h</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              >)</span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >发布含有计算机病毒、木马或者其他恶意程序、链接等任何可能危害公司或用户权益和终端信息安全等的内容；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 30.1pt;
            mso-char-indent-count: 2.5;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(i)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">无论是否为商业目的</font
            ><font face="PingFang SC Regular"
              >,制作、发布与以上行为相关的方法、工具,或对此类方法、工具进行运营或传播；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 30.1pt;
            mso-char-indent-count: 2.5;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(j)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >其他违反法律法规及规范性文件规定、侵犯公司、其他用户合法权益或公司认为不应该、不适当的行为。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">公司设立公众投诉、举报平台</font
            ><font face="PingFang SC Regular">(网页地址：</font></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >https：//www.carwins.com/</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">；客服邮箱</font
            ><font face="PingFang SC Regular">&nbsp;&nbsp;</font></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >service@ carwins.com</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">)；联系电话：</font></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">400-0226-698</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >),用户可按照公司公示的投诉举报制度向公司投诉、举报各类违法违规行为、违法传播活动、违法有害信息、侵权内容、侵权行为等。公司将依法及时受理和处理用户投诉举报，一共同营造文明健康、规范有序、风清正气的网络空间。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">在任何情况下</font
            ><font face="PingFang SC Regular"
              >,如果公司有理由认为您的任何行为违反或可能违反上述行为规范的,公司可独立进行判断并及时处理,且在任何时候有权在不进行任何事先通知的情况下终止向您及/或所属企业提供服务,并向有权机关举报。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular"
                >3、车赢云平台数据使用规范</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">未经公司书面许可</font
            ><font face="PingFang SC Regular"
              >,用户不得自行或授权、允许、协助任何第三人对本协议车赢云平台及相关服务中信息内容进行如下行为：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">3.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >复制、读取、采用车赢云平台及相关服务的信息内容</font
            ><font face="PingFang SC Regular"
              >,用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">3.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >擅自编辑、整理、编排车赢云平台及相关服务的信息内容后在车赢云平台及相关服务的源页面以外的渠道进行展示；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">3.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >采用包括但不限于特殊标识、特殊代码等任何形式的识别方法</font
            ><font face="PingFang SC Regular"
              >,自行或协助第三人对车赢云平台及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">3.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >其他非法获取车赢云平台及相关服务的信息内容的行为。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >4、违约处理及法律责任</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">4.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如用户违反法律法规或规范性文件、本协议或公司其他服务条款</font
            ><font face="PingFang SC Regular"
              >,公司有权独立判断并不经通知即视情况随时采取措施,包括但不限于对相关内容进行部分或全部删除、屏蔽,对违规账号处以包括但不限于警告、删除部分或全部关注用户、限制或禁止使用部分或全部功能、解除用户与所属企业的关系、账号临时或永久冻结或封禁、账号注销,并公告处理结果等,以减轻用户不当行为造成的影响。由此给违规用户带来的损失(包括但不限于通信中断,用户资料及相关数据清空等),由该用户自行承担。公司将保存涉嫌违反法律法规、涉嫌违法犯罪的用户行为的有关记录,并依法向有关主管部门报告、配合有关主管部门调查。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">4.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >因您违反本协议或其他服务条款规定</font
            ><font face="PingFang SC Regular"
              >,引起第三方投诉、要求、诉讼、索赔或损失的,您应当自行承担全部责任。因您的违法或违约行为导致公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的,您还应足额赔偿公司及其关联公司、控制公司因此遭受的全部损失,包括但不限于诉讼费、律师费、和解费用、罚款或生效法律文书中规定的损害赔偿金额及其他直接或间接支出费用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">4.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司尊重并保护法人、公民的知识产权、名誉权、姓名权隐私权等合法权益。您保证</font
            ><font face="PingFang SC Regular"
              >,在使用车赢云平台及相关服务制作、复制、发布、传播的信息时不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。针对第三方对您提出的全部权利主张，您应自行承担全部责任；如因您的侵权行为导致公司及其关联公司、控制公司遭受损失的(包括经济、商誉等损失),您还应足额赔偿公司及其关联公司、控制公司遭受的全部损失,包括但不限于诉讼费、律师费、和解费用、罚款或生效法律文书中规定的损害赔偿金额及其他直接或间接支出费用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">4.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果您或所属企业未按时足额支付车赢云平台使用费</font
            ><font face="PingFang SC Regular"
              >,公司有权进行独立判断并采取相应措施,包括视具体情况限制、中止或终止您使用车赢云平台的许可、冻结账号、限制增加成员等。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">4.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC"
              >为保障对车赢云平台及相关服务的管理措施的有效执行</font
            ><font face="PingFang SC"
              >,避免用户利用注册不同账号、设立或使用不同主体进行注册等方式绕开、避开或对抗公司的管理,对于同一主体或其存在关联关系的主体(包括但不限于存在母子公司、总分公司关系,直接或间接的控股关系,实际控制人、董事监事、高级管理人员与该主体存在直接或者间接控制的关系,协议控制VIE结构,或从证券市场披露、其官方宣传等能够确认存在关联的关系等)单独或合计多次存在违法、违规或违反本协议等公司其它服务条款的行为,公司有权根据相关规定或约定对该等主体及/或其账号进行一并处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular"
                >5、服务的变更、中断和终止</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">5.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解并同意</font
            ><font face="PingFang SC Regular"
              >,公司提供的车赢云平台及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向您提供服务,确保服务的连贯性和安全性。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解</font
            ><font face="PingFang SC Regular"
              >,公司不能随时预见和防范技术以及其他风险,包括但不限于不可抗力、病毒、木马、黑客攻击、软件或系统不稳定、硬件或通信线路故障、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。在法律允许范围内,公司不对上述情形导致的服务终端、数据丢失以及其他的损失和风险承担赔偿责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解并同意</font
            ><font face="PingFang SC Regular"
              >,公司为了服务整体运营的需要,有权在公告通知后修改、中断、中止或终止车赢云平台及相关服务,而无须向用户负责或承担任何赔偿责任。您有责任自行备份存储于车赢云平台及相关服务中的数据,如果您停止使用车赢云平台,公司有权从服务器上永久删除您的账号及数据,且无义务在服务终止后向您提供或返还账号或数据,但法律另有规定的除外。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">6、知识产权</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司尊重和鼓励用户发布原创内容</font
            ><font face="PingFang SC Regular"
              >,认识到保护知识产权对公司生存与发展的重要性,并承诺将保护知识产权作为公司运营的</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">基本原则</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">之一。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">6.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">除非有相关证明</font
            ><font face="PingFang SC Regular"
              >,您在车赢云平台及相关服务上发表的全部原创内容的著作权均归您所有；</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">为了促进知识的分享和传播</font
              ><font face="PingFang SC Regular"
                >,您同意就该等内容授予公司在全世界范围内、免费的、永久的、不可撤销的、非独家的使用许可,且公司有权将该等内容用于公司各种形态的产品和服务上,包括但不限于网站、应用或其他互联网产品。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">6.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司在车赢云平台及相关服务中提供的内容</font
            ><font face="PingFang SC Regular"
              >(包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等)的知识产权属于公司所有。公司提供车赢云平台及相关服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可,任何人不得擅自使用(包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载车赢云平台软件及相关服务中的容)。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">6.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司为车赢云平台开发、运营提供技术支持</font
            ><font face="PingFang SC Regular"
              >,并对车赢云平台及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">6.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请您在任何情况下都不要私自使用公司的包括但不限于</font
            ><font face="PingFang SC Regular"
              >“车赢云平台”、“车赢”、“Carwins”等的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等(以下统称为“标识”)。未经相关权利人(含公司及/或其他原始权利人)事先书面同意,您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等,也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述标识给公司或他人造成损失的,由您承担全部法律责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">6.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您承诺并保证在使用车赢云平台及服务过程中上传、发布的全部内容均有合法来源且已经取得必要的授权</font
            ><font face="PingFang SC Regular"
              >,不会因为上传、发布行为发生知识产权、肖像权等权利的转移。您有责任确保您上传、发布的内容不侵犯他人的合法权利。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >6.6您理解并同意,为向所有用户提供更优质的服务,公司可能会对用户的昵称、账号信息、发表内容等用户公开内容在法律允许的范围内进行使用,包括但不限于提供搜索、链接等服务。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >6.7上述及其他任何车赢云平台或相关服务包含的内容的知识产权均受到法律保护,未经公司或相关权利人许可,用户不得以任何形式进行使用或创造相关衍生作品。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">7、免责声明</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解并同意</font
            ><font face="PingFang SC Regular"
              >,车赢云平台及相关服务可能会受多种因素的影响或干扰,公司不保证(包括但不限于)</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司完全适合用户的使用要求；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">公司不受干扰</font
            ><font face="PingFang SC Regular"
              >,及时、安全、可靠或不出现错误；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >用户经由公司取得的任何软件、服务或其他材料符合用户的期望；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >软件中任何错误都将能得到更正。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您理解并同意</font
            ><font face="PingFang SC Regular"
              >,在使用车赢云平台及相关服务过程中,可能遇到不可抗力等因素(不可抗力是指不能预见、不能克服并不能避免的客观事件),包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时,公司将努力在第一时间及时修复,但因不可抗力给用户造成了损失,您同意公司不承担责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 5.25pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-indent: -6pt;
            mso-char-indent-count: -0.5;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >用户在车赢云平台及相关服务发表的车辆信息或内容仅表明其个人及所属企业（含</font
            ><font face="PingFang SC Regular"
              >4S门店）的立场和观点和该用户及门店的车辆信息、相关立场和观点,并不代表公司的车辆信息、立场或观点。</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">作为内容的发表者</font
              ><font face="PingFang SC Regular"
                >,用户需自行对所发表内容负责,因所发表内容引发的一切纠纷,由该内容的发表用户承担全部法律及连带责任,公司不承担任何连带或者非连带法律责任。</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司依据本协议约定获得的审查、监督、处理违法违规内容的权利</font
            ><font face="PingFang SC Regular"
              >,并不构成公司的义务或承诺,公司不能保证及时发现违法、违规、侵权行为或进行相应处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您阅读、理解并同意：关于车赢云平台及相关服务</font
            ><font face="PingFang SC Regular"
              >,公司不提供任何种类的明示或暗示担保或条件,包括但不限于商业适售性、特定用途适用性等。您对车赢云平台及相关服务的使用行为必须自行承担相应风险</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">。</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您阅读、理解并同意</font
            ><font face="PingFang SC Regular"
              >,本协议是在保障遵守国家法律法规、维护公序良俗,保护他人合法权益,公司在能力范围内尽最大的努力按照相关法律法规进行判断,但并不保证公司判断完全与司法机关、行政机关的判断一致,如因此产生的后果您已经理解并同意自行承担。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.7</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您充分了解并同意</font
            ><font face="PingFang SC Regular"
              >,您通过车赢云平台及相关服务发表的信息为公开的信息,您对任何信息的引用或发表即认可该信息为公开的信息,并单独对此行为承担法律责任；鉴于互联网体制及环境的特殊性,您在服务中引用或发表的内容、信息及个人资料有可能会被他人复制、转载、擅改或做其它用途,脱离您的预期和控制；您在此已充分意识此类风险的存在,并确认此等风险应完全由您自行承担,公司对此不承担任何责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">7.8</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您了解并同意</font
            ><font face="PingFang SC Regular"
              >,在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)及匿名或冒名的信息的风险,基于服务使用规范所述,该等风险应由您自行承担,公司对此不承担任何责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">9、个人信息保护</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">9.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您在注册账号或使用车赢云平台及相关服务过程中</font
            ><font face="PingFang SC Regular"
              >,需要提供一些必要的信息,例如,为了向您提供账号注册服务或进行身份识别,需要您填写手机号码；为了允许您使用车赢云平台及相关服务的特定功能,需要您填写并同意使用所属企业信息等您需要填写真实、完整的身份信息,否则,您可能无法使用车赢云平台及相关服务的全部功能或者在使用过程中受到限制。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">9.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">一般情况下</font
            ><font face="PingFang SC Regular"
              >,您可以随时浏览、修改自己提交的信息,但出于安全性和身份识别的考虑,您可能无法修改注册时提供的初始注册信息及其他验证信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">9.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对于车赢云平台及相关服务如何收集、使用、存储和保护您的个人信息及您就个人信息享有的权利</font
            ><font face="PingFang SC Regular"
              >,您可以阅读《车赢云平台隐私权政策》进一步了解。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">10、转让</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">10.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如公司发生合并、分立、收购、资产转让时</font
            ><font face="PingFang SC Regular"
              >,公司可向第三方转让车赢云平台及相关服务下相关资产；公司也可在适当通知您之后,将本协议下的部分或全部服务及相应的权利义务转交由第三方，在本合同有效期内依照本合同运营或履行。如届时运营方相应修改本合同且您同意的，从其约定。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">10.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您在车赢云平台的注册账号所有权属于公司，您不得转让、赠予、借用、出借或以其他方式提供给您意外的其他人使用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Regular">11、其他</font></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效</font
            ><font face="PingFang SC Regular"
              >,则这些条款将尽可能接近本协议原条文意旨重新解析,且本协议其它规定仍应具有完整的效力及效果。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">若您与公司发生争议的</font
            ><font face="PingFang SC Regular"
              >,双方应尽量友好协商解决,协商不成,您同意将争议提交至上海市杨浦区人民法院诉讼解决。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司有权依据国家政策、有关法律法规及规范性文件、技术条件、产品功能、公司业务发展等变化需要而进行修改本协议</font
            ><font face="PingFang SC Regular"
              >,公司会将修改后的协议予以发布。前述内容一经正式发布,并以适当的方式送达用户(在相关服务页面展示、网站公布、系统通知、电子邮箱、手机短信、常规的信件传送、您注册使用车赢云平台软件或相关服务的账号管理系统内发送站内信等方式中的一种或多种),即为本协议不可分割的组成部分,您应同样遵守。您可以在相关服务页面查阅本协议的最新版本。您对修改后的协议有异议的,请立即停止登录、使用车赢云平台及相关服务,若您登录或继续使用车赢云平台软件及相关服务,视为认可修改后的协议。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您同意</font
            ><font face="PingFang SC Regular"
              >,任何由于电子邮箱、手机号码、通讯地址等信息错误或其他不可归咎于公司的原因,导致您未收到相关规则、通知、提示等信息的,均不影响该等信息对您所产生的法律效力,且您应受其约束,由此产生的一切后果及责任由您</font
            ></span
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">自行承担</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您清楚知悉并同意</font
            ><font face="PingFang SC Regular"
              >,若您有任何事项需要通知公司的,均应当按照公司网站或车赢云平台及相关服务对外正式公布的联系方式或渠道进行提交,否则,公司将无法收到您的通知。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议中的标题仅为方便及阅读而设</font
            ><font face="PingFang SC Regular"
              >,并不影响本协议中任何规定的含义或解释。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.7</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您及所属企业和公司均是独立的主体</font
            ><font face="PingFang SC Regular"
              >,在任何情况下本协议不构成公司对您及所属企业的任何形式的明示或暗示担保或条件,双方之间亦不构成代理、合伙、合营或雇佣关系。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.8</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本协议的任何条款如被法院认定为无效或不可执行</font
            ><font face="PingFang SC Regular"
              >,不影响本协议其他条款的效力。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.9</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">本协议的著作权为公司所有</font
            ><font face="PingFang SC Regular"
              >,公司保留一切解释和修改的权利。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular">11.10</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对车赢云平台及相关服务之任何部分或本协议的任何部分之意见及建议可通过客服与公司联系。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Regular"
                >上海市车赢信息服务有限公司</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                font-weight: bold;
                font-size: 14pt;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            mso-line-height-alt: 12pt;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">2022年4月 第 1 版</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
      </div>
    </body>
  </div>
</template>

<style lang='scss' scoped>
.ml_privacy {
  padding: 15px;
  p,
  span {
    color: #292d33 !important;
    line-height: 26px !important;
  }
}

@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "等线";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri Light";
}

@font-face {
  font-family: "等线 Light";
}

@font-face {
  font-family: "PingFang SC Semibold";
}

@font-face {
  font-family: "PingFang SC Regular";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: 0.0001pt;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 14px;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  margin-top: 12pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  mso-outline-level: 1;
  font-family: "Calibri Light";
  mso-fareast-font-family: "等线 Light";
  mso-bidi-font-family: "Times New Roman";
  color: rgb(47, 84, 150);
  font-size: 14px;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 2pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  mso-outline-level: 2;
  font-family: "Calibri Light";
  mso-fareast-font-family: "等线 Light";
  mso-bidi-font-family: "Times New Roman";
  color: rgb(47, 84, 150);
  font-size: 13pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  layout-grid-mode: char;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 9pt;
}



p.MsoHeader {
  mso-style-name: 页眉;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  border-bottom: 1pt solid windowtext;
  mso-border-bottom-alt: 0.75pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 9pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: 0.0001pt;
  mso-pagination: widow-orphan;
  font-family: "Times New Roman";
  font-size: 10pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}
@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}
@page Section0 {
  margin-top: 72pt;
  margin-bottom: 72pt;
  margin-left: 72pt;
  margin-right: 72pt;
  size: 612pt 792pt;
  layout-grid: 18pt;
  mso-header-margin: 35.4pt;
  mso-footer-margin: 35.4pt;
}
div.Section0 {
  page: Section0;
}
</style>